<template>
    <parent-layout title="IMT Overview">
        <v-tabs>
            <gated-tab
                link
                :to="{ name: 'imt-clients' }"
                :allowed-roles="[roles.ROLE_IMT_CLIENTS_VIEW]"
            >
                Clients
            </gated-tab>
            <gated-tab
                link
                :to="{ name: 'imt-instances' }"
                :allowed-roles="[roles.ROLE_IMT_INSTANCES_VIEW]"
            >
                Instances
            </gated-tab>
            <gated-tab
                link
                :to="{ name: 'imt-authentications' }"
                :allowed-roles="[roles.ROLE_IMT_AUTHENTICATIONS_VIEW]"
            >
                Authentications
            </gated-tab>
            <gated-tab
                link
                :to="{ name: 'accounts-list' }"
                :allowed-roles="[roles.ROLE_ACCOUNT_SERVICE_VIEW]"
            >
                Account Service Data
            </gated-tab>
        </v-tabs>
        <router-view />
    </parent-layout>
</template>

<script>
import ParentLayout from '@/components/layout/ParentLayout'
import GatedTab from '@/components/security/GatedTab'
import roles from '@/helpers/security/roles.js'

export default {
    components: { ParentLayout, GatedTab },
    data() {
        return {
            pageTitle: 'Imt Overview',
            roles: roles,
        }
    },
    computed: {},
}
</script>
